import {
  Box, Container, Heading, Stack
} from "@chakra-ui/react";
import React from 'react';
import FadeInAnimation from "../DefinitionComponents/Animation-Components/FadeInAnimation";
import MaskSlidInAnimation from "../DefinitionComponents/Animation-Components/MaskSlideInAnimation";
import InViewTrigger from "../DefinitionComponents/Utility/UX/InViewTrigger";

function IntroComponentGreenGray3col({headline,text1,text2,text3,text4, variant,colorvariant, darktypo}) {

  return (
    <Box w="100%">

    <Container 
      variant="layoutContainer" 
      px={{base:'8',lg:'10'}} py={20}  maxW={{base:'100%',md:'90%',lg:'8xl'}}
    
      bg={darktypo== "dark"  ?  "brand.darkgray"   :"brand.lightgray"  }

    >
      <Box w={{ lg: "100%", base: "100%" }}>
        <InViewTrigger threshold={0.4} triggerOnce={false}>
          {(inView) => (
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={4}
              align="stretch"
            >
              <Box minW={variant== "thirds"  ?  "25%"   :"50%"  } >
                <MaskSlidInAnimation threshold={1} duration={0.6}>
                  <Heading
                    fontSize="5xl"
                    fontWeight="normal"
                    mb="4"
                    letterSpacing="tighter"
                 
                    color={colorvariant== "blue"  ?  "brand.blue"   :"brand.green"  } 
                  >
              
                   {headline}
                  </Heading>
                </MaskSlidInAnimation>
              </Box>
              <Box>
                <FadeInAnimation threshold={0.4} duration={1} delay={0.4}>
                  <Heading
                    fontSize="2xl"
                    fontWeight="normal"
                    mb="4"
                    color={darktypo== "dark"  ?  "brand.lightgray"   :"brand.meddarkgray"  }
                  >
                      {text1}
                  </Heading>
                </FadeInAnimation>
                <FadeInAnimation threshold={0.4} duration={1} delay={0.8}>
                  <Heading
                    fontSize="xl"
                    fontWeight="normal"
                    color={darktypo== "dark"  ?  "brand.medgray"   :"brand.meddarkgray"  }

                    dangerouslySetInnerHTML={{__html: text2}}
                  >
                    
                  </Heading>
                </FadeInAnimation>
              </Box>
              <Box>
                <FadeInAnimation threshold={0.4} duration={1} delay={0.4}>
                  <Heading
                    fontSize="xl"
                    fontWeight="normal"
                    mb="4"
                    color={darktypo== "dark"  ?  "brand.lightgray"   :"brand.meddarkgray"  }

                  >
                     {text3}
                  </Heading>
                </FadeInAnimation>
                <FadeInAnimation threshold={0.4} duration={1} delay={0.8}>
                  <Heading
                    fontSize="md"
                    fontWeight="normal"
                    color={darktypo== "dark"  ?  "brand.medgray"   :"brand.meddarkgray"  }
                    dangerouslySetInnerHTML={{__html: text4}}
                  >
                   
                  </Heading>
                </FadeInAnimation>
              </Box>
            </Stack>
          )}
        </InViewTrigger>
      </Box>
    </Container>
  </Box>
  )
}

export default IntroComponentGreenGray3col