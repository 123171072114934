import { Box, Button, Container, Text } from "@chakra-ui/react";
import React from "react";
import ImgTextModule from "../components/ImgTextModule";
import FadeInAnimation from "../DefinitionComponents/Animation-Components/FadeInAnimation";
import RouterLink from "../DefinitionComponents/Utility/Routing/RouterLink";

function TeamQuadLink() {
  return (
    <Container
      variant="layoutContainer"
      mt={0.5}
      mb={0.5}
      display="grid"
      gridGap={0.5}
      gridTemplateColumns={{
        lg: "repeat(4, 1fr)",
        md: "repeat(2, 1fr)",
        sm: "repeat(1, 1fr)",
      }}
    >
      <ImgTextModule
        minH={{ base: "500px", lg: "400px" }}
        w="100%"
        src={"oliver-header.mp4"}
        delay={0}
        duration={1}
        threshold={0.4}
        initialY={0}
        initialX={0}
        video={true}
        mediaOpacity={0.8}
        bgColor="black"
      >
        {/* Use the children you like */}
        <Box p={8}>
          <FadeInAnimation
            threshold={0.4}
            delay={0.4}
            duration={0.7}
            initialX={0}
            initialY={-10}
          >
            <Text color="white" fontSize="4xl">
              Oliver Michel
            </Text>
          </FadeInAnimation>
          <FadeInAnimation
            threshold={0.4}
            delay={1}
            duration={0.7}
            initialX={0}
            initialY={10}
          >
            <Text color="brand.green" fontSize="md">
              CEO & Founder
            </Text>
          </FadeInAnimation>
          <RouterLink isExternal={false} link={"/team/oliver-michel"}>
            <Button
              mt={4}
              variant="solid"
              bg="brand.green"
              color="brand.darkgray"
            >
              Profile
            </Button>
          </RouterLink>
        </Box>
      </ImgTextModule>
      <ImgTextModule
        minH="400px"
        w="100%"
        src={"sarah-header.mp4"}
        delay={0.3}
        duration={1}
        threshold={0.4}
        initialY={0}
        initialX={0}
        video={true}
        mediaOpacity={0.8}
        bgColor="black"
      >
        <Box p={8}>
          <FadeInAnimation
            threshold={0.4}
            delay={0.4}
            duration={0.7}
            initialX={0}
            initialY={-10}
          >
            <Text color="white" fontSize="4xl">
              Sarah Payne
            </Text>
          </FadeInAnimation>

          <FadeInAnimation
            threshold={0.4}
            delay={1}
            duration={0.7}
            initialX={0}
            initialY={10}
          >
            <Text color="brand.green" fontSize="md">
              COO
            </Text>
          </FadeInAnimation>
          <RouterLink isExternal={false} link={"/team/sarah-payne"}>
            <Button
              mt={4}
              variant="solid"
              bg="brand.green"
              color="brand.darkgray"
            >
              Profile
            </Button>
          </RouterLink>
        </Box>
      </ImgTextModule>
      <ImgTextModule
        minH="400px"
        w="100%"
        minW={"300px"}
        src={"header-benedikt.mp4"}
        delay={0.6}
        duration={1}
        threshold={0.4}
        initialY={0}
        initialX={0}
        video={true}
        mediaOpacity={0.8}
        bgColor="black"
      >
        <Box p={8}>
          <FadeInAnimation
            threshold={0.4}
            delay={0.4}
            duration={0.7}
            initialX={0}
            initialY={-10}
          >
            <Text color="white" fontSize="4xl">
              Benedikt Schulz
            </Text>
          </FadeInAnimation>

          <FadeInAnimation
            threshold={0.4}
            delay={1}
            duration={0.7}
            initialX={0}
            initialY={10}
          >
            <Text color="brand.green" fontSize="md">
              Investment Manager
            </Text>
          </FadeInAnimation>
          <RouterLink isExternal={false} link={"/team/benedikt-schulz"}>
            <Button
              mt={4}
              variant="solid"
              bg="brand.green"
              color="brand.darkgray"
            >
              Profile
            </Button>
          </RouterLink>
        </Box>
      </ImgTextModule>
      <ImgTextModule
        minH="400px"
        w="100%"
        src={"header-mona.mp4"}
        delay={0.9}
        duration={1}
        threshold={0.4}
        initialY={0}
        initialX={0}
        video={true}
        mediaOpacity={0.8}
        bgColor="black"
      >
        <FadeInAnimation threshold={0.4} duration={1}>
          <Box p={8}>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Text color="white" fontSize="4xl">
                Mona Tiesler
              </Text>
            </FadeInAnimation>

            <FadeInAnimation
              threshold={0.4}
              delay={1}
              duration={0.7}
              initialX={0}
              initialY={10}
            >
              <Text color="brand.green" fontSize="md">
                Investment Manager
              </Text>
            </FadeInAnimation>
            <RouterLink isExternal={false} link={"/team/mona-tiesler"}>
              <Button
                mt={4}
                variant="solid"
                bg="brand.green"
                color="brand.darkgray"
              >
                Profile
              </Button>
            </RouterLink>
          </Box>
        </FadeInAnimation>
      </ImgTextModule>
    </Container>
  );
}

export default TeamQuadLink;
