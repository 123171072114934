import { Container, Flex } from "@chakra-ui/react";
import React from "react";
import TokentusCard from "../components/TokentusCard";

function PortfolioTeaser(bgvariant) {
  return (
  
    <Container variant="layoutContainer" p={{base:0,md:8}} mb={8}  bg={bgvariant== "dark"  ?  "brand.black"  :"brand.light.gray"  }>

      <Flex flexDirection={{ base: 'column', lg: 'row' }}>
            <TokentusCard
              layoutId={'test-01'}
              bgColor={'brand.green'}
              textColor={'brand.darkgray'}
              btnColor={'brand.green'}
              btnBg={'brand.darkgray'}
              headingText={'We invest in you.'}
              sublineText={'We are looking for targets that strive to change business from scratch.'}
              headingSize={'4xl'}
              sublineSize={{ base:'sm', md:'lg',lg:'md'}}
              btnText={'Blockchain Companies'}
              headingTag={'h2'}
              sublineTag={'p'}
              headingId={'tokentus-heading-01'}
              sublineId={'tokentus-subline-01'}
              link={'/targets'}
              isExternal={false}
       
              height={{base:300, md:400}}
              toplineText={'We with you.'}
              toplineSize='md'
              toplineId={'topline-id0222'}
              toplineTag='h4'
              width='100%'
            />
            <TokentusCard
              layoutId={'test-02'}
              bgColor={'brand.blue'}
              textColor={'white'}
              btnVariant={'solid'}
              btnColor={'brand.blue'}
              btnBg={'brand.green'}
              headingText={'You invest in us.'}
              sublineText={'You are looking for a solid future proof partner to invest in.'}
              headingSize={'4xl'}
              sublineSize={{ base:'sm', md:'lg',lg:'md'}}
              btnText={'Blockchain Investors'}
              headingTag={'h2'}
              sublineTag={'p'}
              headingId={'tokentus-heading-02'}
              sublineId={'tokentus-subline-02'}
              link={'/investors'}
              isExternal={false}
          
              height={{base:300, md:400}}
              toplineText={'You with us.'}
              toplineSize='md'
              toplineId={'topline-id02222'}
              toplineTag='h4'
              width='100%'
            />
          </Flex>

      
    

    </Container>
  )
}

export default PortfolioTeaser